import React from "react"
import Layout from "../components/App/Layout"
import SEO from "../components/App/SEO"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import { graphql, Link } from "gatsby"
import PerfectSynchImg from "../assets/images/features/synchronized-calendar.webp"
import IntegratedCalendarImg from "../assets/images/features/integrated-calendar-m.webp"
import ScheduleCalendarImg from "../assets/images/features/schedule-calendar-m.webp"
import SynchronizedCalendarImg from "../assets/images/features/synchronized-calendar.webp"
import SynchronizedCalendarMImg from "../assets/images/features/synchronized-calendar-m.webp"

const Calendar = ({ data }) => {
  return (
    <Layout env={data.site.siteMetadata.env}>
      <SEO
        postTitle="BlueMail Calendar"
        postDescription="BlueMail Calendar for Exchange and CalDAV accounts"
        postImage="/img/OG/og_image-Calendar.png"
        postURL="calendar"
        postSEO
      />
      <Navbar />
      <div className="features-functions">
        <div className="container-fluid integrated-calendar w-707 r mt-fs">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-xs-12">
                <h1>
                  BlueMail <span>Calendar</span>
                </h1>
                <p>
                  BlueMail's Integrated Calendar can be viewed from right within
                  our app and we make sure it is automatically synced as new
                  events are created and updated on the fly. In one tap, you can
                  plan events, invite your contacts, and set notifications all
                  within our easy to use Calendar view.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={IntegratedCalendarImg} alt="Integrated calendar" />
            </div>
          </div>
        </div>
        <div className="container-fluid schedule-calendar w-707 l">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 offset-lg-6 col-md-6 offset-md-6 col-xs-12">
                <h2>
                  <span>Balanced</span> Schedule
                </h2>
                <p>
                  Easily find the right balance for planning your daily and
                  weekly schedule by toggling between Day and Agenda views. With
                  our scrollable BlueMail Calendar, you can create reminders for
                  future events with ease, allowing you to stay organized and on
                  top of your schedule.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid w-991">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 mt-3 mb-5 p-0">
              <img src={ScheduleCalendarImg} alt="Schedule calendar" />
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-xs-12 mt-100 mb-50 text-center">
                <h2>
                  <span>Perfectly</span> Synchronized
                </h2>
                <p>
                  The BlueMail Calendar is compatible with Exchange ActiveSync
                  accounts, Google accounts, and any CalDAV account. BlueMail
                  allows for seamless integration of .ics file invites into your
                  BlueMail Calendar.{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-xs-12 p-0 mb-100">
              <img
                className="d-992"
                src={SynchronizedCalendarImg}
                alt="Perfectly Synchronized"
              />
              <img
                className="w-991 mt-3 mb-5"
                src={SynchronizedCalendarMImg}
                alt="Perfectly Synchronized mobile"
              />
            </div>
          </div>
        </div>
        <div className="container">
          <div className="col-lg-12 col-md-12 col-xs-12 pb-100">
            <p>
              Learn more about the BlueMail{" "}
              <Link to="https://bluemail.me/help/calendar/">
                <span>Calendar</span>
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </Layout>
  )
}

export default Calendar

export const query = graphql`
  query CalendarPageQuery {
    site {
      siteMetadata {
        title
        env
      }
    }
  }
`
